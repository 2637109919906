<template>
  <div class="exchang">
    <img src="../../assets/mei/1.png" alt="" />
    <img src="../../assets/mei/2.png" alt="" />
    <img src="../../assets/mei/3.png" alt="" />
    <img src="../../assets/mei/4.png" alt="" />
    <img src="../../assets/mei/5.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.exchang {
  text-align: center;
  margin: 20px 0;
  img {
    text-align: center;
  }
}
</style>